




















































































































































@import '~@/styles/import';

.paymentstatus__tasks {
  @apply grid grid-cols-paymentStatus gap-16;
}
